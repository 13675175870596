import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { Grid, CardContent, Typography,CardActionArea, CardActions,Button} from '@mui/material';
import Data from "./Data.json";

export default function About() {
  return (
    <>
     
      <Container maxWidth="lg">

        <Typography variant='h4' align='center' style={{ marginTop: "100px" }}>
         Our Achievements & Prestigious Awards
        </Typography>
        <Grid container spacing={5} style={{ marginTop: "20px" }}>
          {Data.map((result, index)=>(
            <Grid item xs={12} sm={4} ms={4} key={index}>

              <Card sx={{ maxWidth: 345 }} style={{padding:"10px" , marginBottom:"30px"}}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={result.img}
                    alt="green iguana"
                    style={{borderRadius:"5px"}}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Lizard
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                <Button variant="contained" size="medium">
                  View
                </Button>
                </CardActions>
              </Card>

            </Grid>
          ))}
        </Grid>
      </Container>
    </>

  )
}