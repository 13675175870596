import React, { useState,useEffect } from "react";
import {
  AppBar,
  Button,  
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
//import "./Review.css";
import Footer from "./Footer";
//import "./AlumniRegForm.css";
import Typography from "@mui/material/Typography";
import { v4 } from "uuid";

import { ref, 
  uploadBytes,
  getDownloadURL,
  listAll,
  list, } from "firebase/storage";

import { storage } from "./firebase";


const Login = () => {

  const [value, setValue] = useState();
  const theme = useTheme(); 
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

 
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);


  const imagesListRef = ref(storage, "mulitpleFiles/");


  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `mulitpleFiles/${imageUpload.name+ v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      alert("Uploaded succesfully");
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      console.log(response);
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);


  return (   
      
    <React.Fragment>
    <AppBar sx={{ background: "#063970" }}>
      <Toolbar>
       <img src="images/orkidicon.png" alt=""  sx={{ transform: "scale(2)"}}  /> 
              Orkid Software Services
        {isMatch ? (
          <>
            <Typography sx={{ fontSize: "1.1rem", paddingLeft: "15%" }}>             
            </Typography>             
            <DrawerComp />
          </>
        ) : (
          <>
            <Tabs
              sx={{ marginLeft: "auto" }}
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={(e, value) => setValue(value)}
            >            
            </Tabs>
            
            <Button sx={{ marginLeft: "auto" }} variant="contained" >
            Alumni Registration
            </Button>
            <Button sx={{ marginLeft: "20px" }} variant="contained">
            Notice Board 
            </Button>
            <Button sx={{ marginLeft: "20px" }} variant="contained">
            Contactus Dashboard
            </Button>
           
          </>
        )}
      </Toolbar>
      </AppBar>

     
    
      <div className="App">
      <h1> Notice Board </h1>
    
      <input
        type="file"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
        }}
      />
      <button onClick={uploadFile}> Upload Image</button>
      {imageUrls.map((url) => {
        return <img src={url} />;
      })}
      </div>    
      
      
      <div className="space" >
        Copyright ©2024 Orkid Software Services
        <Footer />
      </div>
 

  
  </React.Fragment>

  
    
  );
 
  
};



export default Login;