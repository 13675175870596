import React, { useState } from "react";
import {
  AppBar,
  Button,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
import Carousel from "./Carousel";
import Review from "./Review";
import "./Review.css";
import About from "./About";
import "./About";
import Clients from "./Clients";
import Contact from "./Contact";
import { useNavigate } from "react-router-dom";


const Header = () => {
  const [value, setValue] = useState();
  const theme = useTheme(); 
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const history = useNavigate();

  const handleclick = () =>{  
  history("/Alumni");
  }

  const handleLogin = () =>{  
    history("/Login");
    }

    const handleLogout = () =>{  
      history("/Logout");
      }

      
  return (
    <React.Fragment>
      <AppBar sx={{ background: "#063970" }}>
        <Toolbar>
         <img src="images/orkidicon.png" alt=""  sx={{ transform: "scale(2)"}}  /> 
                Orkid Software Services
          {isMatch ? (
            <>
              <Typography sx={{ fontSize: "1.1rem", paddingLeft: "15%" }}>             
              </Typography>             
              <DrawerComp />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >            
              </Tabs>
              
              <Button sx={{ marginLeft: "auto" }} variant="contained" onClick={()=> handleclick({})}>
                Alumni Registration
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained" onClick={()=> handleLogin({})} >
                Notice Board
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained" onClick={()=> handleLogout({})} >
               Image & Gallery
              </Button>
             
            </>
          )}
        </Toolbar>
        </AppBar>

   

        <Carousel/>    
        
      
     <About/>
      <Clients/>
      <Review/>  
      
     
        <div className="App">
        <h1>Contact Us </h1>
        </div>    
        <Contact/>   
   
   
  
      
    </React.Fragment>
    
  );
 
  
};



export default Header;