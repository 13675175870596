import Header from "./Header";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";
import Alumni from "./Alumni";

export default function App() {
   return (
      <BrowserRouter>
      <Routes>
         <Route path="/" element={ <Header />}></Route>
         <Route path="/Alumni" element={ <Alumni />}></Route>
         <Route path="/Login" element={ <Login />}></Route>
         <Route path="/Logout" element={ <Logout />}></Route>
      </Routes>
               
      </BrowserRouter>
   );
}


