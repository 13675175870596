import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from './ClientSlider';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from 'react-awesome-reveal';

let clients = [
    {
        name: "Dr.M.Mirdha",
        position: "SENIOR OBSTETRICIAN & GYNAECOLOGIST ",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 5,
        disc: `I recently partnered with OSS to develop a secure online portal for my practice.  As a busy doctor, I was impressed by OSS's understanding of the specific needs of healthcare providers. They worked closely with my team to create a user-friendly platform that streamlines communication with patients and improves overall efficiency.`
    },
    {
        name: "Dr.Bhabani Bhuyan",
        position: "SENIOR GASTROENTEROLOGY CONS. & MS",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 4,
        disc: `OSS designed the portal to facilitate secure messaging between patients and my staff. This has improved communication and reduced phone call volume.The portal's features have streamlined my workflow and freed up valuable time for patient care. I can now review lab results and send secure messages remotely, improving overall efficiency.`
    },
    {
        name: "Dr.Rishik Raj Pathak",
        position: "Oral & Maxillofacial Surgeon",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 5,
        disc: `The portal has been a game-changer for patient engagement. Patients can now easily schedule appointments, refill prescriptions, and access their medical records securely.Security and HIPAA compliance were top priorities throughout the development process. I feel confident that patient data is safe and secure on the platform.`
    },
    {
        name: "Jogen Bania",
        position: "Rishi Tours & Travels",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 5,
        disc: `I recently partnered with OSS to develop a new online platform for my tours and travel agency.Their understanding of the travel industry and focus on exceptional customer experience made them the perfect partner.The portal allows us to showcase our tours in a visually appealing way, with detailed descriptions, high-quality photos, and interactive maps. `
    },
    {
        name: "M.Ahmed",
        position: "Hotel Middletown",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 5,
        disc: `Since launching the new portal, we've seen a significant increase in direct bookings. The platform is intuitive and user-friendly, making it easier for guests to book directly with us.The portal integrates seamlessly with our existing property management system. This simplifies reservation management, reduces manual work, and offers real-time insights into booking trends.`
    },
]
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: true,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,             
                infinite: true,
                dots: true,
                arrows: true,
            }
        },
        {
            breakpoint: 370,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,              
                infinite: true,
                dots: true,
                arrows: true,
            }
        }
    ]
}

const Clients = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <Container id='client'>
            <Slide direction="left">
                <span className="green"></span>
                <h1>Reviews</h1>
            </Slide>
            <Testimonials>
                <Slider ref={arrowRef} {...settings}>
                    {clientDisc}
                </Slider>
                <Buttons>
                    <button
                        onClick={() => arrowRef.current.slickPrev()}
                    ><IoIosArrowBack /></button>
                    <button
                        onClick={() => arrowRef.current.slickNext()}
                    ><IoIosArrowForward /></button>
                </Buttons>
            </Testimonials>
        </Container>
    )
}

export default Clients

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        text-transform: capitalize;
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #01be96;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`

const Testimonials = styled.div`
    margin-top: 2rem;
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #01be96;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`