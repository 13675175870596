import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AfroStyles from "./afroStyles";
import Marquee from 'react-fast-marquee';
import logo from "../src/WhatsApp.png";
import "./index.css";

function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,   
    slidesToShow: 2,
    slidesToScroll: 2 ,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,          
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
            
        }
      },
   ]
    
  };

  
  return (
    <>
    <div className="content">      
    <h1 className="header"><Marquee direction="left" speed={50} delay={5} pauseOnHover={true}>Think & Implement</Marquee></h1>
    <div className="container">
      <Slider {...settings}>
         {AfroStyles.map((item) => (
          <div key={item.id}>
            <div className="img-body">
              <img src={item.src} alt={item.alt} />
            </div>
            <div>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        ))}      
      </Slider>
    </div>
  </div> 

  {/* WHATSAPP INTEGRATION */}
  <div className="whatsapp_float">   
  <a href="https://wa.me/917002922525" target="_blank" rel="noreferrer">
  <img src={logo} width="50px" />
  </a> 
  </div>

  
  </>
  );
}

export default SimpleSlider;
