const AfroStyles = [
    {
      id: 1,
     /* title: "PWA",
      description:
        "PWA (Progressive Web App)",
      alt: "PWA",*/
      src: "/images/home9.avif",
    },
    {
      id: 2,
      /* title: "AAD",
      description:
        "Android App Developement",
      alt: "Android Developer",*/
      src: "/images/home7.avif",
    },
    {
      id: 3,
      /* title: "IOSAD",
      description: "IOS App Developement",
      alt: "IOS Developer",*/
      src: "/images/home8.avif",
    },
    {
      id: 4,
      /* title: "WAW",
     description: "Web App vs Website",
      alt: "App Comparision",*/
      src: "/images/home10.avif",
    },
    {
      id: 5,
      /* title: "WAW",
      description: "Web App vs Website",
      alt: "App Comparision",*/
      src: "/images/home5.avif",
    },
    {
      id: 6,
      /* title: "WAW",
      description: "Web App vs Website",
      alt: "App Comparision",*/
      src: "/images/home6.avif",
    },    
    
  ];
  
  export default AfroStyles;